<ion-app>
	<ion-menu #menu side="end" menuId="mainMenu" contentId="main" *ngIf="user?.emailVerified">
		<ion-header>
			<ion-toolbar>

				@if(user?.photoUrl){
				<ion-avatar slot="start" style="height: 42px; width: 42px; margin: 0 10px;">
					<img [src]="user!.photoUrl | bypassSecurity: 'url'" />
				</ion-avatar>
				}
				@else{
				<app-user-icon-svg style="height: 36px; width: 36px; margin: 0 10px;" slot="start"></app-user-icon-svg>
				}

				<ion-text class="username">{{user?.email}}</ion-text>
				<ion-buttons slot="end">

					<ion-button (click)="menu.close()">
						<ion-icon name="close-outline"></ion-icon>
					</ion-button>

				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-list>
				<ion-menu-toggle>

					@if(user?.subscription?.status === 'active')
					{
					<ion-item lines="none" (click)="settings(menu)" button>
						<ion-icon name="settings-outline" slot="start"></ion-icon>
						<ion-label>Settings</ion-label>
					</ion-item>
					}

					@if(user?.subscription?.status === 'active' && onboardingCompleted)
					{
					<ion-item lines="none" (click)="feedback(menu)" button>
						<ion-icon name="checkbox-outline" slot="start"></ion-icon>
						<ion-label>Feedback</ion-label>
					</ion-item>
					}

					<ion-item lines="none" (click)="onLogOut(menu)" button>
						<ion-icon name="exit" slot="start"></ion-icon>
						<ion-label>Logout</ion-label>
					</ion-item>

				</ion-menu-toggle>
			</ion-list>
		</ion-content>
	</ion-menu>
	<ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
<ion-loading message="Loading..." spinner="circles"></ion-loading>