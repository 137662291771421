import { JsonPipe, NgIf } from '@angular/common';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { Router, RouterModule } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Subscription } from '@invertase/firestore-stripe-payments';
import { AppCenterPush } from '@ionic-native/app-center-push/ngx';
import { Platform } from '@ionic/angular';
import { IonApp, IonAvatar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonLoading, IonMenu, IonMenuToggle, IonRouterOutlet, IonText, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { add, business, checkboxOutline, checkmark, closeOutline, exit, home, logoIonic, settingsOutline } from 'ionicons/icons';
import { take, takeUntil } from 'rxjs';
import { FacebookAuthService } from 'src/app.implementations/authentication-external/facebook/facebook-auth.service';
import { GoogleAuthService } from 'src/app.implementations/authentication-external/google/google-auth.service';
import { MicrosoftAuthService } from 'src/app.implementations/authentication-external/microsoft/microsoft-auth.service';
import { FirebaseAuthService } from 'src/app.implementations/authentication/firebase/security/firebase.auth.service';
import { StripeFirebasePaymentService } from 'src/app.implementations/payment-provider/stripe-firebase/stripe-firebase-payment.service';
import { IAuthService } from './security/interfaces/auth.service';
import { BaseComponent } from './shared/base-classes/base.component';
import { UserIconSvgComponent } from './shared/components/user-icon-svg/user-icon-svg.component';
import { AppUser } from './shared/models/app-user.model';
import { BypassSecurityPipe } from './shared/pipes/bypass-security.pipe';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	standalone: true,
	providers: [AppCenterPush],
	imports: [IonAvatar, UserIconSvgComponent, IonButton, IonButtons, IonImg, IonLoading, IonText, JsonPipe, IonItem, IonMenuToggle, BypassSecurityPipe,
		IonLabel, IonIcon, IonContent, IonList, IonTitle, IonToolbar, IonHeader, IonApp, IonMenu, IonRouterOutlet, RouterModule, NgIf],
})
export class AppComponent extends BaseComponent implements OnInit
{
	@ViewChild(IonLoading, { static: true }) ionLoading!: IonLoading;

	previousVal: boolean = false;
	isAuthenticated: boolean = false;
	isAppBoot: boolean = true;
	subscription!: Subscription | null;
	currentUser!: User;
	user: AppUser | null = null;
	user1: User | null = null;
	onboardingCompleted: boolean = false;

	constructor(
		firestore: Firestore, // required to make @angular/fire work
		private firebaseAuthService: FirebaseAuthService,
		private zone: NgZone,
		private stripeFirebaseService: StripeFirebasePaymentService,
		private authService: IAuthService,
		private router: Router,
		private platform: Platform,
		private microsoftAuthService: MicrosoftAuthService,
		private googleAuthService: GoogleAuthService,
		private facebookAuthService: FacebookAuthService)
	{
		super();
		addIcons({ home, business, checkboxOutline, exit, closeOutline, settingsOutline, add, logoIonic, checkmark });
	}

	async ngOnInit()
	{
		this.ionLoading.present();
		this.monitorAuthState();

		if (Capacitor.isNativePlatform())
		{
			this.authService.user$.pipe(take(1)).subscribe(user =>
			{
				if (user)
				{
					this.initializeAppListeners();
				}
			});
		}
	}

	async initializeAppListeners()
	{
		this.platform.ready().then(() =>
		{
			this.ionLoading.dismiss();

			this.platform.resume.subscribe(() =>
			{
				this.microsoftAuthService.checkAndSetAuthStatus();
			});
		});

		// Handle the URL that the app was opened with from a browser (our custom redirect page)
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) =>
		{
			this.zone.run(() =>
			{
				this.handleAppAuthRedirect(event);

			});
		});
	}

	handleAppAuthRedirect(event: URLOpenListenerEvent)
	{
		if (event.url.includes('code') && event.url.includes(`state=${this.microsoftAuthService.state}`))
		{
			this.microsoftAuthService.handleAppRedirect(event)
				.then(() => this.router.navigateByUrl('/main/contacts/import'));
		}

		if (event.url.includes('code') && event.url.includes(`state=${this.googleAuthService.state}`))
		{
			this.googleAuthService.handleAppRedirect(event)
				.then(() => this.router.navigateByUrl('/main/contacts/import'));
		}

		if (event.url.includes('access_token') && event.url.includes(`state=${this.facebookAuthService.state}`))
		{
			this.facebookAuthService.handleAppRedirect(event)
				.then(() => this.router.navigateByUrl('/main/contacts/import'));
		}
	}

	getSubscription(user: User)
	{
		return this.stripeFirebaseService.getUserSubscription(user.uid);
	}

	monitorAuthState()
	{
		this.authService.user$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(async (user) =>
			{
				this.user = user ?? null;
				let isAuthenticated = !!user;

				// If user is signed out and was previously signed in, redirect to login page
				if (!isAuthenticated && this.previousVal !== isAuthenticated)
				{
					this.router.navigateByUrl('/signin');
				}

				if (!this.previousVal && isAuthenticated)
				{

					if (user?.subscription)
					{

					}
					else
					{
						if (!this.router.url.includes('/subscribe'))
						{
							this.router.navigateByUrl('/subscribe');
						}
					}
				}

				this.previousVal = isAuthenticated;
			});
	}

	async onLogOut(menu: IonMenu)
	{
		menu.close();
		this.authService.logout();
	}

	async feedback(menu: IonMenu)
	{
		menu.close();
		this.router.navigateByUrl('main/feedback');
	}

	async settings(menu: IonMenu)
	{
		menu.close();
		this.router.navigateByUrl('main/settings');
	}
}
