import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Firestore, collection, getDocs, query, where } from "@angular/fire/firestore";
import { Subscription } from "@invertase/firestore-stripe-payments";
import { StripeService } from "ngx-stripe";
import { switchMap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class StripeFirebasePaymentService implements IPaymentProviderService
{
    private firestore = inject(Firestore);

    constructor(
        private http: HttpClient,
        private stripeService: StripeService)
    {
    }

    checkout()
    {
        return this.http.post('/create-checkout-session', {})
            .pipe(
                switchMap((session: any) =>
                {
                    return this.stripeService.redirectToCheckout({ sessionId: session.id });
                })
            );
    }

    async getProducts(): Promise<any[]>
    {
        const itemCollection = collection(this.firestore, 'stripe-products')
        const appQuery = query(itemCollection, where('active', '==', true));

        const querySnapshot = await getDocs(appQuery)

        const productsPromises = querySnapshot.docs.map(async (productDoc) =>
        {
            let productInfo = productDoc.data();

            // fetch prices subcollection per product
            const pricesCollection = collection(productDoc.ref, 'prices');
            const pricesQuery = query(pricesCollection, where('active', '==', true));
            const priceQuerySnapshot = await getDocs(pricesQuery);

            // assume there is only one price per product
            const priceDoc = priceQuerySnapshot.docs[0];
            if (priceDoc)
            {
                productInfo['priceId'] = priceDoc.id;
                productInfo['priceInfo'] = priceDoc.data();
            }
            return productInfo;
        });

        const products = await Promise.all(productsPromises);
        return products;
    }

    async getUserSubscription(userId: string): Promise<Subscription | null>
    {
        const q = query(
            // currentUser is provided by firebase, via getAuth().currentUser
            collection(this.firestore, 'stripe-customers', userId, 'subscriptions'),
            where('status', 'in', ['trialing', 'active'])
        );

        // fetch the active subscriptions
        let querySnapshot = await getDocs(q);
        if (querySnapshot.empty)
        {
            return null;
        }
        let sub = querySnapshot.docs[0].data() as Subscription;
        return sub;
    }
}

export interface IPaymentProviderService { }