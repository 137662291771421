import { inject } from "@angular/core";
import { CanMatchFn, Router } from "@angular/router";
import { map } from "rxjs";
import { IAuthService } from "../interfaces/auth.service";

export const shouldBeOnboardingGuard: CanMatchFn = (route, state) =>
{
    const authService: IAuthService = inject(IAuthService);
    const router: Router = inject(Router);

    return authService.user$
        .pipe(
            map(user =>
            {
                if (!user)
                {
                    router.navigateByUrl('/');
                    return false;
                }

                if (user.userInfo?.onboardingCompleted)
                {
                    router.navigateByUrl('/main');
                    return false;
                }

                return true;
            }));
}