import { Injectable, Signal } from "@angular/core";
import { Observable } from "rxjs";
import { AppUser } from "../../shared/models/app-user.model";

@Injectable()
export abstract class IAuthService
{
    abstract user$: Observable<AppUser | null>;
    abstract currentUserSig: Signal<AppUser | null>;
    abstract login(email: string, password: string): Promise<boolean>;
    abstract signup(email: string, password: string): Promise<boolean>
    abstract logout(): void;
}