import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastController: ToastController) { }

    async showError(error: any, userMessage: string = 'An error occurred') {
        const toast = await this.toastController.create({
            message: userMessage,
            duration: 3000,
            position: 'bottom',
            color: 'danger'
        });
        toast.present();
    }

    async showSuccess(message: string) {
        const toast = await this.toastController.create({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'success'
        });
        toast.present();
    }
}