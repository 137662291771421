import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { IAuthService } from '../interfaces/auth.service';

export const userCanViewPaymentPageGuard: CanMatchFn = (route, state) => {
	const authService: IAuthService = inject(IAuthService);
	const router: Router = inject(Router);

	return authService.user$
		.pipe(
			map((user) => {
				if (!user) {
					router.navigateByUrl('/signin');
					return false;
				}

				if (!user.emailVerified) {
					router.navigateByUrl('/verify-email');
					return false;
				}

				if (!user.subscription) {
					return true;
				}

				router.navigateByUrl('/signin');
				return false;
			}));
};
