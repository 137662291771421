import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import { Auth, UserCredential, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signOut, user } from '@angular/fire/auth';
import { Subscription } from '@invertase/firestore-stripe-payments';
import { Observable, of, switchMap } from 'rxjs';
import { StripeFirebasePaymentService } from 'src/app.implementations/payment-provider/stripe-firebase/stripe-firebase-payment.service';
import { IAuthService } from 'src/app/security/interfaces/auth.service';
import { AppUser, AppUserInfo } from 'src/app/shared/models/app-user.model';
import { UserDataService } from 'src/app/shared/services/user-data.service';

@Injectable({
	providedIn: 'root'
})
export class FirebaseAuthService implements IAuthService, OnDestroy
{
	// Don't use constructor. Use inject(). This is because of implementation of IAuthService, to keep things separated.
	private userDataService = inject(UserDataService);
	private stripeFirebaseService = inject(StripeFirebasePaymentService);
	currentUserSig = signal<AppUser | null>(null);
	sub: Subscription | null = null;
	userInfo: AppUserInfo | null = null;

	user$ = user(this.firebaseAuth)
		.pipe(switchMap(async user =>
		{
			if (!user)
			{
				this.currentUserSig.set(null);
				return null;
			}
			
			//console.warn('user', user);

			let sub = await this.stripeFirebaseService.getUserSubscription(user.uid);

			let appUser: AppUser = {
				id: user.uid,
				email: user.email!,
				emailVerified: user.emailVerified,
				token: await user.getIdToken(),
				tokenExpirationDate: new Date(),
				subscription: sub,
				photoUrl: user.photoURL,
				userInfo: await this.userDataService.getUserAppInfo(user.uid)
			}

			this.currentUserSig.set(appUser);
			return appUser;

		}));

	userInfo$: Observable<AppUserInfo | null> = this.user$.pipe(switchMap(user =>
	{
		if (!user)
		{
			return of(null);
		}

		return this.userDataService.getUserAppInfo$(user.id);
	}));

	constructor(private firebaseAuth: Auth) { }

	async login(email: string, password: string): Promise<boolean>
	{
		let loggedIn: boolean = await signInWithEmailAndPassword(this.firebaseAuth, email, password)
			.then(async credentials =>
			{

				return !!credentials;
			}).catch(error =>
			{
				console.error('signInWithEmailAndPassword error', error);
				return false;
			});

		return loggedIn;
	}

	async signup(email: string, password: string): Promise<boolean>
	{
		let credentials: UserCredential | null = await createUserWithEmailAndPassword(this.firebaseAuth, email, password)
			.then(async credentials =>
			{
				return credentials;
			}).catch(error =>
			{
				console.error('createUserWithEmailAndPassword error', error);
				return null;
			});

		if (credentials)
		{
			await sendEmailVerification(this.firebaseAuth.currentUser!);
		}
		return !!credentials;
	}

	logout(): Promise<void>
	{
		return signOut(this.firebaseAuth);
	}

	getToken()
	{

	}

	ngOnDestroy()
	{
		return;
	}
}
