import { computed, Injectable, signal, WritableSignal } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { GoldenContact } from "functions/src/import/import.models";
import { map, Observable } from "rxjs";
import { MergeContact, MergeGroup } from "src/app/main/merge/merge.models";
import { BaseComponent } from "../base-classes/base.component";

@Injectable({
    providedIn: 'root'
})
export class FirestoreGoldenContactsService extends BaseComponent
{
    private mergeGroups_sw: WritableSignal<MergeGroup[]> = signal([] as MergeGroup[]);
    public mergeGroups_s = computed(this.mergeGroups_sw);

    constructor(
        private ngFirestore: AngularFirestore,
    )
    {
        super();
    }

    // CONTACTS

    async getContact(userId: string, contactid: string): Promise<GoldenContact>
    {
        const docRef = this.ngFirestore.collection('user-data').doc(userId).collection('golden_contacts').doc(contactid);
        let doc = await docRef.ref.get();
        return doc.data() as GoldenContact;
    }

    dismissMergeGroup(userId: string, contacts: GoldenContact[]): Promise<void>
    {
        const batch = this.ngFirestore.firestore.batch();

        contacts.forEach(contact =>
        {
            const docRef = this.ngFirestore.collection(`user-data/${userId}/golden_contacts`).doc(contact.id).ref;
            batch.update(docRef, { mergeGroupId: null });
        });
        console.log('dismissMergeGroup')
        return batch.commit();
    }

    dismissMergeContact(userId: string, contact: GoldenContact)
    {
        const docRef = this.ngFirestore.collection(`user-data/${userId}/golden_contacts`).doc(contact.id);
        return docRef.set({ mergeGroupId: null }, { merge: true });
    }

    setContactToMain(userId: string, group: MergeGroup)
    {
        // Delete all other contacts that were not picked, using firestore batch
        const batch = this.ngFirestore.firestore.batch();
        group.contacts.forEach(contact =>
        {
            if (contact.id !== group.pickedContactId)
            {
                const docRef = this.ngFirestore.collection(`user-data/${userId}/golden_contacts`).doc(contact.id).ref;
                batch.update(docRef, { mergeGroupId: null, deleted: true });
            }
        });

        // Update the picked contact to remove the mergeGroupId
        const pickedContactRef = this.ngFirestore.collection(`user-data/${userId}/golden_contacts`).doc(group.pickedContactId!).ref;
        batch.update(pickedContactRef, { mergeGroupId: null });

        return batch.commit();
    }

    getDocumentsGroupedByMergeId(userId: string): Observable<MergeGroup[]>
    {
        return this.ngFirestore
            .collection<GoldenContact>(`user-data/${userId}/golden_contacts`, ref =>
                ref
                    .where('deleted', '==', false)
                    .where('mergeGroupId', '!=', null))
            .snapshotChanges()
            .pipe(
                map(snapshot =>
                {
                    console.warn('snapshot', snapshot);

                    const groupedDocs = new Map<string, MergeGroup>();

                    snapshot.forEach((doc) =>
                    {
                        const data: GoldenContact = doc.payload.doc.data();
                        const mergeGroupId = data.mergeGroupId;
                        if (mergeGroupId)
                        {
                            if (!groupedDocs.has(mergeGroupId))
                            {
                                groupedDocs.set(mergeGroupId, {
                                    mergeGroupId,
                                    contacts: [],
                                    advanced: false,
                                    dismissedContacts: [],
                                    merged: false
                                });
                            }

                            groupedDocs.get(mergeGroupId)!.contacts.push({
                                id: doc.payload.doc.id,
                                ...data
                            });

                            let mergedResult: MergeContact = this.getMergeContact(groupedDocs.get(mergeGroupId)!.contacts)
                            groupedDocs.get(mergeGroupId)!.mergedResult = mergedResult;

                            //let groupContacts = groupedDocs.get(mergeGroupId)!.contacts
                            //groupedDocs.get(mergeGroupId)!.mergedResult = getMergedContactFromGroup(groupContacts);
                        }
                    });

                    let newArray = Array.from(groupedDocs.values());
                    this.mergeGroups_sw.set(newArray)

                    return newArray;
                })
            );
    }

    private getMergeContact(contacts: GoldenContact[]): MergeContact
    {
        let mergedContact: MergeContact = {} as MergeContact;

        contacts.forEach(contact =>
        {
            if (contact.name && !mergedContact.name)
            {
                mergedContact.name = contact.name;
            }
            else if (contact.name && mergedContact.name && (contact.name.length > mergedContact.name.length))
            {
                mergedContact.name = contact.name;
            }

            if (contact.phoneNumbers)
            {
                if (!mergedContact.phoneNumbers)
                {
                    mergedContact.phoneNumbers = [];
                }

                contact.phoneNumbers?.forEach(phoneNumber =>
                {
                    if (!mergedContact.phoneNumbers!.find(p => p.value === phoneNumber.value))
                    {
                        mergedContact.phoneNumbers!.push(phoneNumber);;
                    }
                });

            }

            if (contact.emailAddresses)
            {
                mergedContact.emailAddresses = contact.emailAddresses;
            }

            if (contact.images)
            {
                mergedContact.images = contact.images;
            }

            if (contact.coverPhotos)
            {
                mergedContact.coverPhotos = contact.coverPhotos;
            }
        });

        return mergedContact
    }
}