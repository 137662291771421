import { Routes } from '@angular/router';
import { firstTimeUserGuard } from './security/guards/first-time-user.guard';
import { shouldBeLoggedInGuard } from './security/guards/should-be-logged-in.guard';
import { shouldBeOnboardingGuard } from './security/guards/should-be-onboarding';
import { shouldRedirectToMainGuard } from './security/guards/should-redirect-to-main.guard';
import { userCanViewPaymentPageGuard } from './security/guards/user-can-view-payment-page.guard';
import { userLoggedInAndVerifiedGuard } from './security/guards/user-logged-in-and-verified.guard';

export const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
		pathMatch: 'full',
		canActivate: [shouldRedirectToMainGuard],
	},
	{
		path: 'home',
		redirectTo: '',
	},
	{
		path: 'signin',
		loadComponent: () => import('./account-management/signin/signin.page').then(m => m.LoginPage),
		canActivate: [shouldRedirectToMainGuard],
	},
	{
		path: 'signup',
		loadComponent: () => import('./account-management/signup/signup.page').then(m => m.SignupPage),
		canActivate: [shouldRedirectToMainGuard]
	},
	{
		path: 'onboarding',
		canActivate: [shouldBeLoggedInGuard, shouldBeOnboardingGuard],
		canActivateChild: [shouldBeLoggedInGuard, shouldBeOnboardingGuard],
		children: [
			{
				path: '',
				loadComponent: () => import('./onboarding/onboarding.page').then(m => m.OnboardingPage),
			},
			{
				path: 'linkedin',
				loadComponent: () => import('./connect/linkedin/connect-linkedin.page').then(m => m.ConnectLinkedInPage),
			},
			{
				path: 'google',
				loadComponent: () => import('./connect/google/connect-google.page').then(m => m.ConnectGooglePage),
			}
		]
	},
	{
		path: 'connect',
		canActivate: [shouldBeLoggedInGuard],
		canActivateChild: [shouldBeLoggedInGuard],
		children: [
			{
				path: '',
				loadComponent: () => import('./connect/choose-provider/choose-provider.page').then(m => m.ChooseProviderPage),
			},
			{
				path: 'linkedin',
				loadComponent: () => import('./connect/linkedin/connect-linkedin.page').then(m => m.ConnectLinkedInPage),
			},
			{
				path: 'google',
				loadComponent: () => import('./connect/google/connect-google.page').then(m => m.ConnectGooglePage),
			}
		]
	},
	{
		path: 'main',
		loadComponent: () => import('./main/main.page').then(m => m.MainPage),
		canActivate: [userLoggedInAndVerifiedGuard, firstTimeUserGuard],
		canActivateChild: [userLoggedInAndVerifiedGuard, firstTimeUserGuard],
		children: [
			{
				path: '',
				redirectTo: 'dashboard',
				pathMatch: 'full'
			},
			{
				path: 'settings', children: [
					{
						path: '',
						loadComponent: () => import('./main/settings/settings.page').then((m) => m.SettingsPage),
						children: [
							{
								path: '',
								redirectTo: 'account',
								pathMatch: 'full'
							},
							{
								path: 'account',
								loadComponent: () => import('./main/settings/account/account.page').then((m) => m.AccountPage),
							},
							{
								path: 'connections',
								loadComponent: () => import('./main/settings/connections/connections.page').then((m) => m.ConnectionsPage),
							}
						]
					}
				]
			},
			{
				path: 'dashboard', children: [
					{
						path: '',
						loadComponent: () => import('./main/dashboard/dashboard.page').then((m) => m.DashboardPage),
					}
				]
			},
			{
				path: 'todos', children: [
					{
						path: '',
						loadComponent: () => import('./main/todos/todos.page').then((m) => m.TodosPage),
					}
				]
			},
			{
				path: 'scan', children: [
					{
						path: '',
						loadComponent: () => import('./main/scan/scan.page').then((m) => m.ScanPage),
					}
				]
			},
			{
				path: 'calendar', children: [
					{
						path: '',
						loadComponent: () => import('./main/calendar/calendar.page').then((m) => m.CalendarPage),
					}
				]
			},
			{
				path: 'contacts',
				children: [
					{
						path: '',
						loadComponent: () => import('./main/contacts/contacts.page').then((m) => m.ContactsPage),
					},
					{
						path: 'new',
						loadComponent: () => import('./main/contacts/new-contact/new-contact.page').then((m) => m.NewContactPage),
					},
					{
						path: 'edit/:contactid',
						loadComponent: () => import('./main/contacts/edit-contact/edit-contact.page').then((m) => m.EditContactPage),
					},
					{
						path: ':contactid',
						loadComponent: () => import('./main/contacts/contact/contact.page').then((m) => m.ContactPage),
					}
				]
			},
			{
				path: 'reports',
				loadComponent: () => import('./main/reports/reports.page').then(m => m.ReportsPage)
			},
			{
				path: 'merge',
				loadComponent: () => import('./main/merge/merge.page').then(m => m.MergePage)
			},
			{
				path: 'feedback',
				loadComponent: () => import('./main/feedback/feedback.page').then(m => m.FeedbackPage)
			}
		]
	},
	{
		path: 'verify-email',
		loadComponent: () => import('./account-management/verify-email/verify-email.page').then(m => m.VerifyEmailPage)
	},
	{
		path: 'subscribe',
		canActivate: [userCanViewPaymentPageGuard],
		loadComponent: () => import('./account-management/subscribe/subscribe.page').then(m => m.SubscribePage),
		children: [
			{
				path: 'success',
				loadComponent: () => import('./account-management/subscribe/success/success.page').then(m => m.SuccessPage)
			},
			{
				path: 'cancel',
				loadComponent: () => import('./account-management/subscribe/cancel/cancel.page').then(m => m.CancelPage)
			},
			{
				path: 'redirect', // Used to redirect from browser back to mobile app after returning from payment provider
				loadComponent: () => import('./account-management/subscribe/redirect/redirect.page').then(m => m.RedirectPage)
			}
		]
	},
	{
		path: '**',
		loadComponent: () => import('./not-found/not-found.page').then(m => m.NotFoundPage)
	},
];
