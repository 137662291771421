import { Environment } from "./environment.model";

export const environment: Environment = {
	baseUrl: 'https://app-dev.borwintech.com',
	production: false,
	canny: {
		key: '540b01f0-73ba-dcb1-2afd-ab284b22010b'
	},
	stripe: {
		key: 'pk_test_51PRW3NP0KYJHMluehpcXO0MfzH6IGhVIbRFO50LdGV8GD8TjXC8wZI8gbXYBIXrN6XnnQUe7MmglX8hM2KcHwM7B00OFB8Na0j',
		successUrl: 'https://app-dev.borwintech.com/subscribe',
		cancelUrl: 'https://app-dev.borwintech.com/subscribe',
	},
	google: {
		clientId: '954757244938-bqm5h2idd2cjmphnbb6jikmprreep4lr.apps.googleusercontent.com',
		apiKey: 'AIzaSyAQ7mff-WMqXLLQVSdwhbNBvD9xlXAlfKQ',
		scope: 'openid profile email https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/calendar.readonly',
	},
	linkedIn: {
		clientId: '78nnscuvensigh',
		apiKey: '',
		scope: 'openid liteprofile emailaddress w_member_social',
	},
	microsoft: {
		clientId: '81f98149-d023-4b4d-a240-48502d7b4831',
		tenantId: '88fc1459-33e3-487e-9e1b-038a6cb4639b',
		redirectUri: 'https://app-dev.borwintech.com/main/contacts/import',
		scope: ['User.Read', 'Contacts.Read'],
		authority: 'https://login.microsoftonline.com/consumers',
	},
	facebook: {
		appId: '1158944078792360',
		version: 'v20.0',
		redirectUrl: 'https://app-dev.borwintech.com/main/contacts/import',
		scope: 'user_friends'
	},
	firebase: {
		apiKey: "AIzaSyDXesf0ERUVQkAVHno4ZYuAoYGIARmivD0",
		authDomain: "borwintech.com",
		databaseURL: "https://intrepid-honor-376613-default-rtdb.europe-west1.firebasedatabase.app",
		projectId: "intrepid-honor-376613",
		storageBucket: "intrepid-honor-376613.appspot.com",
		messagingSenderId: "954757244938",
		appId: "1:954757244938:web:da9b62a19811186db1d7f3",
		measurementId: "G-14RKDSM2XP"
	},
	functions: {
		importContactsUrl: 'https://importcontacts-5hsieraxpa-ew.a.run.app',
		googleTokenUrl: 'https://getgoogletoken-5hsieraxpa-ew.a.run.app',
		getGoogleEventsUrl: 'https://getgoogleevents-5hsieraxpa-ew.a.run.app',
	}
};