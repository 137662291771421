import { Injectable, signal } from '@angular/core';
import { URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Storage } from '@ionic/storage-angular';
import { AccountAndScopes, AuthData, ProviderAuthData, ProviderAuthDatas, ProviderType } from 'functions/src/providers/auth/models';
import { GoogleRequestParams } from 'functions/src/providers/google/auth/models';
import { lastValueFrom, Observable, takeUntil } from 'rxjs';
import { FirebaseAuthService } from 'src/app.implementations/authentication/firebase/security/firebase.auth.service';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { FirestoreUserDataService } from 'src/app/shared/services/firestore-user-data.service';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import { environment } from 'src/environments/environment';
import { googleScopes } from './google-auth.models';

@Injectable({
    providedIn: 'root'
})
export class GoogleAuthService extends BaseComponent
{
    linkedAccounts_s = signal<string[]>([]);
    authRedirectResponseError_s = signal<boolean>(false);
    initialized: boolean = false;

    readonly state = 'g56789';
    private readonly STORAGE_KEY_CODE_VERIFIER = 'g_code_verifier';
    private readonly CLIENT_ID = environment.google.clientId;
    private readonly SCOPE = environment.google.scope;
    private readonly AUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';

    constructor(
        private storage: Storage,
        private functionsService: FunctionsService,
        private firestoreService: FirestoreUserDataService,
        authService: FirebaseAuthService
    )
    {
        super();
        authService.user$.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(user =>
            {
                if (user && !this.initialized)
                {
                    this.initialized = true;

                    this.initStorage();
                    this.initLinkedAccounts();
                }
            });
    }

    async initStorage()
    {
        await this.storage.create();
    }

    async initLinkedAccounts()
    {
        let linkedAccounts = await this.getLinkedAccounts()
        this.linkedAccounts_s.set(linkedAccounts);
    }

    // [1] Initial sign-in request: show the oauth consent screen
    async signIn(forceSelect: boolean = false)
    {
        const codeVerifier = this.generateCodeVerifier();
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        await this.storage.set(this.STORAGE_KEY_CODE_VERIFIER, codeVerifier);

        const authUrl = this.buildAuthCodeUrl(codeChallenge, forceSelect);

        if (Capacitor.isNativePlatform())
        {
            //TODO: testen in echte app
            //await Browser.open({ url: authUrl, presentationStyle: 'popover' } as OpenOptions);
            window.location.href = authUrl;
        }
        else
        {
            window.location.href = authUrl;
        }
    }

    // [2] Handle the redirect from Google, and send request to FUNCTION to get and store tokens
    async handleBrowserRedirect(code: string, allowedScopes: string[])
    {
        this.authRedirectResponseError_s.set(false);
        const codeVerifier = await this.storage.get(this.STORAGE_KEY_CODE_VERIFIER);

        // Send code to FUNCION to get tokens and store them in Firestore
        await lastValueFrom(this.getAndStoreTokensWithCode(code, codeVerifier))
            .catch(error =>   
            {
                this.authRedirectResponseError_s.set(true);
            });

        // If the exchangeCodeForTokens call succeeded, token information was now stored in Firestore and can be retrieved from there
        this.linkedAccounts_s.set(await this.getLinkedAccounts());
    }

    public async handleAppRedirect(event: URLOpenListenerEvent): Promise<void>
    {
        try
        {
            let fragment = event.url.split('#')[1];
            if (fragment)
            {
                this.processRedirectFragment(fragment);
            }
            else
            {
                //this.toast.handleError(new Error(`No token found in redirectUrl ${event.url}`), 'Failed to complete login');
            }
        }
        catch (error)
        {
            //this.toast.handleError(error, 'Failed to complete login');
        }
    }

    private async processRedirectFragment(fragment: string)
    {
        this.authRedirectResponseError_s.set(false);

        // get code from fragment
        const params = new URLSearchParams(fragment);
        const code = params.get('code');
        if (!code)
        {
            throw new Error('No code found in Google redirect fragment');
        }

        const codeVerifier = await this.storage.get(this.STORAGE_KEY_CODE_VERIFIER);

        // Send code to FUNCION to get tokens and store them in Firestore
        await lastValueFrom(this.getAndStoreTokensWithCode(code, codeVerifier))
            .catch(error =>   
            {
                this.authRedirectResponseError_s.set(true);
            });

        // If the exchangeCodeForTokens call succeeded, token information was now stored in Firestore and can be retrieved from there
        this.linkedAccounts_s.set(await this.getLinkedAccounts());
    }

    // [3] Send code to FUNCTION and let it fetch tokens. This will store token info in Firestore. We need to refresh the collection after this request is completed.
    private getAndStoreTokensWithCode(code: string, codeVerifier: string): Observable<any>
    {
        let redirectUri = this.getRedirectUrl();
        let body: GoogleRequestParams = {
            client_id: this.CLIENT_ID,
            code: code,
            code_verifier: codeVerifier,
            grant_type: 'authorization_code',
            redirect_uri: redirectUri
        }

        return this.functionsService.getGoogleToken(body);
    }

    private buildAuthCodeUrl(codeChallenge: string, forceAccountSelect: boolean = false): string
    {
        let redirectUri = this.getRedirectUrl();
        let authUrl = this.AUTH_ENDPOINT +
            `?client_id=${this.CLIENT_ID}` +
            `&response_type=code` +
            `&access_type=offline` +
            `&redirect_uri=${encodeURIComponent(redirectUri)}` +
            `&scope=${encodeURIComponent(this.SCOPE)}` +
            `&code_challenge=${codeChallenge}` +
            `&code_challenge_method=S256` +
            `&state=${this.state}`;

        if (forceAccountSelect)
        {
            authUrl += `&prompt=select_account`;
        }

        return authUrl;
    }

    private getRedirectUrl(): string
    {
        // https://localhost:8100/connect/google
        // https://app-dev.borwintech.com/connect/google
        // https://localhost:8100/main/settings/connections
        // https://app-dev.borwintech.com/main/settings/connections

        if (Capacitor.getPlatform() === 'web')
        {
            return window.location.origin + window.location.pathname;
        }
        else
        {
            return `${environment.baseUrl}/${window.location.pathname}`;
        }
    }

    private generateCodeVerifier(): string
    {
        const array = new Uint8Array(32);
        crypto.getRandomValues(array);
        return this.base64UrlEncode(array);
    }

    private async generateCodeChallenge(verifier: string): Promise<string>
    {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const digest = await crypto.subtle.digest('SHA-256', data);
        return this.base64UrlEncode(new Uint8Array(digest));
    }

    private base64UrlEncode(array: Uint8Array): string
    {
        return btoa(String.fromCharCode.apply(null, Array.from(array)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }

    async signOut(email: string): Promise<void>
    {
        try
        {
            await lastValueFrom(this.functionsService.googleLogout(email));
            this.linkedAccounts_s.set(await this.getLinkedAccounts());
        }
        catch (error: any)
        {
            throw error;
        }
    }

    async getLinkedAccounts(): Promise<string[]>
    {
        const accounts = await this.getStoredAccounts();
        return Object.keys(accounts);
    }

    async getLinkedAccountsWithScopes(): Promise<AccountAndScopes[]>
    {
        const accounts = await this.getStoredAccounts();

        let accountArray = [];
        for (let prop of Object.keys(accounts))
        {
            accountArray.push(JSON.parse(JSON.stringify(accounts[prop])));
        }

        let accs: ProviderAuthData[] = accountArray.filter((account: ProviderAuthData) => account.allowedScopes.includes(googleScopes.calendarReadonly_urlScope));
        return accs.map(x => { return { email: x.email, scopes: x.allowedScopes } });
    }

    async getLinkedAccountsWithCalendarPermission(): Promise<string[]>
    {
        const accounts = await this.getStoredAccounts();

        let accountArray = [];
        for (let prop of Object.keys(accounts))
        {
            accountArray.push(JSON.parse(JSON.stringify(accounts[prop])));
        }

        let accs: ProviderAuthData[] = accountArray.filter((account: ProviderAuthData) => account.allowedScopes.includes(googleScopes.calendarReadonly_urlScope));
        return accs.map(x => x.email);
    }

    async getLinkedServices(email: string)
    {
        const accounts = await this.getStoredAccounts();
        return accounts[email]?.allowedScopes;
    }

    async getStoredAccounts(): Promise<ProviderAuthDatas>
    {
        let data = await this.firestoreService.getAuthData(ProviderType.GOOGLE);
        let accounts = data.docs.map(x => x.data()) as AuthData[];

        let googleAccounts: ProviderAuthDatas = {};
        accounts.forEach(account =>
        {
            googleAccounts[account.account] = account.tokenData as ProviderAuthData;
        });

        return googleAccounts;
    }
}