export interface ProviderAuthDatas
{
    [email: string]: ProviderAuthData;
}

export interface ProviderAuthData
{
    userId?: string;
    accessToken: string;
    refreshToken?: string;
    idToken: string;
    expirationTime: number;
    email: string;
    allowedScopes: string[];
}

export interface AccountAndScopes
{
    email: string;
    scopes: string[];
}

export interface AuthData<T = any>
{
    tokenData: T;
    account: string;
    provider: ProviderType;
}

export enum ProviderType
{
    LINKEDIN = 'LINKEDIN',
    OUTLOOK = 'OUTLOOK',
    FACEBOOK = 'FACEBOOK',
    GOOGLE = 'GOOGLE'
}