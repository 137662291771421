import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Storage } from '@capacitor/storage';
import { BehaviorSubject, Observable, catchError, lastValueFrom, throwError } from 'rxjs';
import { ToastService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
import { AuthData } from './microsoft-auth.models';

/** 
 *  Authentication service that follows OAUTH2 CODE FLOW to fetch a code while authorizing our App,
 *  then exchanges the code for an access token and refresh token.
 * 
 *  The access token is used to fetch the users contacts via the Microsoft Graph API.
 */

@Injectable({
    providedIn: 'root'
})
export class MicrosoftAuthService
{
    linkedAccounts = signal<string[]>([]);
    authRedirectResponseError = signal<boolean>(false);

    msTokenValid = signal(false);
    readonly state = 'ms12345';

    private readonly code_url = 'https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize';
    private readonly token_url = 'https://login.microsoftonline.com/consumers/oauth2/v2.0/token';
    private readonly graph_base_url = 'https://graph.microsoft.com/v1.0/me';
    private readonly code_verifier = 'SIYg1t7sw_oDG21vNE6eWxiuYEDiS7nWmmlnKZTzKmc';
    private readonly code_challenge = 'nR0A-udBgZKdLXo0qUxycEtOruXSJIX3RbxRiltX2Rc';
    private readonly storage_key = 'azure_auth';

    private tokenSubject = new BehaviorSubject<string | null>(null);
    private authStatusSubject = new BehaviorSubject<boolean>(false);

    private clientId = environment.microsoft.clientId;
    private scopes = environment.microsoft.scope;

    constructor(
        private http: HttpClient,
        private toast: ToastService,
        private auth: Auth
    )
    {
        this.checkAndSetAuthStatus();
    }

    public async logout()
    {
        await this.removeStoredToken();
        this.authStatusSubject.next(false);
        this.msTokenValid.set(false);
        this.toast.showSuccess('Successfully logged out');
    }

    public async checkAndSetAuthStatus(): Promise<void>
    {
        try
        {
            const authData = await this.getStoredToken();
            if (authData)
            {
                const isValid = await this.validateToken(authData.token);
                this.authStatusSubject.next(isValid);
                this.msTokenValid.set(isValid);
                if (isValid)
                {
                    this.tokenSubject.next(authData.token);
                } else
                {
                    await this.removeStoredToken();
                }
            } else
            {
                this.authStatusSubject.next(false);
                this.msTokenValid.set(false);
            }
        } catch (error)
        {
            this.toast.showError(error, 'Failed to check authentication status');
            this.authStatusSubject.next(false);
            this.msTokenValid.set(false);
        }
    }

    private isLoggedIn(): Observable<boolean>
    {
        return this.authStatusSubject.asObservable();
    }

    public async login(): Promise<void>
    {
        try
        {
            let redirectUri = this.getRedirectUrl();
            const authUrl = this.code_url +
                `?client_id=${this.clientId}` +
                `&response_type=code` +
                `&redirect_uri=${encodeURIComponent(redirectUri)}` +
                `&scope=${encodeURIComponent(this.scopes.join(' '))}` +
                `&response_mode=query` +
                `&code_challenge_method=S256` +
                `&code_challenge=${this.code_challenge}` +
                `&state=${this.state}`;

            if (Capacitor.isNativePlatform())
            {
                //TODO: testen in echte app
                //await Browser.open({ url: authUrl });

                window.location.href = authUrl;
            }
            else
            {
                window.location.href = authUrl;
            }
        }
        catch (error)
        {
            this.toast.showError(error, 'Failed to initiate login');
        }
    }

    public async handleAppRedirect(event: URLOpenListenerEvent): Promise<void>
    {
        try
        {
            let code = this.getCodeFromUrl(event.url);
            if (code)
            {
                await this.getTokenWithCode(code);
            }
            else
            {
                this.toast.showError(new Error(`No code found in redirectUrl ${event.url}`), 'Failed to complete login');
            }
        }
        catch (error)
        {
            this.toast.showError(error, 'Failed to complete login');
        }
    }

    public async handleBrowserRedirect(code: string): Promise<void>
    {
        try
        {
            if (code)
            {
                await this.getTokenWithCode(code);
            }
            else
            {
                this.toast.showError(new Error(`No code found in redirectUrl ${window.location.href}`), 'Failed to complete login');
            }
        }
        catch (error)
        {
            this.toast.showError(error, 'Failed to complete login');
        }
    }

    private async processRedirectCode(code: string)
    {
        await this.getTokenWithCode(code);
    }

    private async getTokenWithCode(code: string)
    {
        try
        {
            let tokenResponse = await this.getTokenFromCode(code);
            let user = this.auth.currentUser;

            let authData: AuthData = {
                userId: user!.uid,
                token: tokenResponse.access_token,
                tokenExpirationDate: ''
            }

            await this.setStoredToken(authData);

            this.tokenSubject.next(tokenResponse.access_token);
            this.authStatusSubject.next(true);
            this.msTokenValid.set(true);
            this.toast.showSuccess('Successfully logged in');
        }
        catch (error)
        {
            this.toast.showError(error, 'Failed to complete login');
        }
    }

    private getRedirectUrl(): string
    {
        if (Capacitor.getPlatform() === 'web')
        {
            return window.location.origin + window.location.pathname;
        }
        else
        {
            return environment.microsoft.redirectUri;
        }
    }

    private getCodeFromUrl(url: string)
    {
        if (url.indexOf(environment.microsoft.redirectUri!) !== -1 &&
            url.includes(`state=${this.state}`))
        {
            let splitUrl = url.split('code=');
            let codeAndState = splitUrl[1].split('&');
            let code = codeAndState[0];

            return code;
        }

        return null;
    }

    private async getTokenFromCode(code: string): Promise<any>
    {
        let redirectUri = this.getRedirectUrl();
        const body = new URLSearchParams();
        body.set('client_id', this.clientId);
        body.set('scope', this.scopes.join(' '));
        body.set('code', code);
        body.set('code_verifier', this.code_verifier);
        body.set('redirect_uri', redirectUri);
        body.set('grant_type', 'authorization_code');

        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        let obs = this.http.post(this.token_url, body.toString(), { headers: headers })
            .pipe(catchError(this.handleHttpError));

        return lastValueFrom(obs);
    }

    private async validateToken(token: string): Promise<boolean>
    {
        try
        {
            await lastValueFrom(this.http.get(this.graph_base_url, {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
            }).pipe(
                catchError(this.handleHttpError)
            ));
            return true;
        } catch
        {
            return false;
        }
    }



    private handleHttpError = (error: HttpErrorResponse) =>
    {
        let errorMessage = 'An error occurred';
        if (error.error instanceof ErrorEvent)
        {
            // Client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else
        {
            // Server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        this.toast.showError(error, errorMessage);
        return throwError(() => new Error(errorMessage));
    }

    private async getStoredToken(): Promise<AuthData | null>
    {
        const { value } = await Storage.get({ key: this.storage_key });

        if (value)
        {
            return JSON.parse(value) as AuthData;
        }

        return null;
    }

    private async setStoredToken(token: AuthData): Promise<void>
    {
        await Storage.set({ key: this.storage_key, value: JSON.stringify(token) });
    }

    private async removeStoredToken(): Promise<void>
    {
        await Storage.remove({ key: this.storage_key });
    }











    public async getContacts(): Promise<any>
    {
        const token = this.tokenSubject.value;
        if (!token)
        {
            throw new Error('Not authenticated');
        }

        return lastValueFrom(this.http.get(`${this.graph_base_url}/contacts`, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
        }).pipe(
            catchError(this.handleHttpError)
        ));
    }
}