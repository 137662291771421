import { Injectable, OnDestroy } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { FirebaseAuthService } from "src/app.implementations/authentication/firebase/security/firebase.auth.service";
import { MergeGroup } from "src/app/main/merge/merge.models";
import { FirestoreGoldenContactsService } from "../services/firestore-golden-contacts.service";
import { SignalsSimpleStoreService } from "./state.service";

export interface MergeGroupsState
{
    mergeGroups: MergeGroup[];
}

@Injectable({
    providedIn: 'root'
})
export class MergeGroupsStateService extends SignalsSimpleStoreService<MergeGroupsState> implements OnDestroy 
{
    constructor(
        private authService: FirebaseAuthService,
        private goldenContactsService: FirestoreGoldenContactsService
    )
    {
        super();

        // this.authService.user$.subscribe(user =>
        // {
        //     if (user)
        //     {
        //         this.loadInitialData(user.id);
        //     }
        // });
    }

    loadInitialData(userId: string)
    {
        this.goldenContactsService.getDocumentsGroupedByMergeId(userId)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe();
    }

    protected ngUnsubscribe = new Subject<void>();

    ngOnDestroy(): void
    {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}