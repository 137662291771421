import { inject } from "@angular/core";
import { CanMatchFn, Router } from "@angular/router";
import { map } from "rxjs";
import { AppUser } from "src/app/shared/models/app-user.model";
import { IAuthService } from "../interfaces/auth.service";

export const firstTimeUserGuard: CanMatchFn = (route, state) =>
{
    const authService: IAuthService = inject(IAuthService);
    const router: Router = inject(Router);

    return authService.user$
        .pipe(
            map((user: AppUser | null) =>
            {
                if (user?.userInfo?.onboardingCompleted)
                {
                    return true;
                }

                router.navigateByUrl('/onboarding');
                return false;
            }));
}