import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/compat/firestore";
import { DocumentData, DocumentReference, Firestore, QuerySnapshot, addDoc, collection, doc, getDocs, query, where, writeBatch } from '@angular/fire/firestore';
import { ImportSessionData, ImportSessionStatus, ImportType } from "functions/src/import/import.models";
import { AddAppNotifcationModel, AppNotificationModel } from "functions/src/notifications/models";
import { ProviderType } from "functions/src/providers/auth/models";
import { takeUntil } from "rxjs";
import { AuthData } from "src/app.implementations/authentication-external/facebook/facebook-auth.models";
import { FirebaseAuthService } from "src/app.implementations/authentication/firebase/security/firebase.auth.service";
import { BaseComponent } from "../base-classes/base.component";
import { AppUser, OnboardingProvider } from "../models/app-user.model";

@Injectable({
    providedIn: 'root'
})
export class FirestoreUserDataService extends BaseComponent
{
    user: AppUser | null = null;

    constructor(
        private firestore: Firestore,
        private ngFirestore: AngularFirestore,
        authService: FirebaseAuthService
    )
    {
        super();
        authService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => { this.user = user; });
    }

    // ONBOARDING

    completeOnboarding(userid: string): Promise<void>
    {
        const docRef = this.ngFirestore.collection('user-data').doc(userid);
        return docRef.set({ 'onboardingCompleted': true }, { merge: true });
    }

    async startOnboardingWithProviders(onbProviders: OnboardingProvider[]): Promise<void>
    {
        let providersSnapshot = await getDocs(collection(this.firestore, `user-data/${this.user!.id}/onboarding_providers`));
        let providers = providersSnapshot.docs.map(doc => doc.data() as OnboardingProvider);

        for (let provider of onbProviders)
        {
            if (providers.find(p => p.providerType === provider.providerType))
            {
                continue;
            }

            await addDoc(
                collection(this.firestore, `user-data/${this.user!.id}/onboarding_providers`),
                provider
            );
        }
    }

    async getOnboardingProviders(): Promise<OnboardingProvider[]>
    {
        let providersSnapshot = await getDocs(collection(this.firestore, `user-data/${this.user!.id}/onboarding_providers`));
        let providers = providersSnapshot.docs.map(doc => doc.data() as OnboardingProvider);
        return providers;
    }

    getOnboardingProvidersCollection(): AngularFirestoreCollection<OnboardingProvider>
    {
        return this.ngFirestore.collection(`user-data/${this.user!.id}/onboarding_providers`)
    }

    // AUTHENTICATION

    setAuthData(authData: AuthData): Promise<DocumentReference<DocumentData, DocumentData>>
    {
        return addDoc(
            collection(this.firestore, `user-data/${this.user!.id}/auth_data`),
            authData
        );
    }

    getAuthData(providerType: ProviderType): Promise<QuerySnapshot<DocumentData, DocumentData>>
    {
        const q = query(
            collection(this.firestore, `user-data/${this.user!.id}/auth_data`),
            where("provider", "==", providerType)
        );

        return getDocs(q);
    }

    // IMPORTS

    addImportSession(importSessionData: ImportSessionRequestData): Promise<DocumentReference<DocumentData, DocumentData>>
    {
        return addDoc(
            collection(this.firestore, `user-data/${this.user!.id}/import_sessions`),
            importSessionData
        );
    }

    getImportSessions(): AngularFirestoreCollection<ImportSessionData>
    {
        return this.ngFirestore.collection(`user-data/${this.user!.id}/import_sessions`)
    }

    // NOTIFICATIONS

    addNotification(notification: AddAppNotifcationModel): Promise<DocumentReference<DocumentData, DocumentData>>
    {
        return addDoc(
            collection(this.firestore, `user-data/${this.user!.id}/notifications`),
            notification
        );
    }

    getNotifications(): AngularFirestoreCollection<AppNotificationModel>
    {
        return this.ngFirestore.collection(`user-data/${this.user!.id}/notifications`, ref => ref.orderBy('createdOn', 'desc'));
    }

    async clearAllNotifications(ids: string[])
    {
        const batch = writeBatch(this.firestore);

        ids.forEach((docId) =>
        {
            batch.delete(doc(this.firestore, `user-data/${this.user!.id}/notifications`, docId))
        })

        await batch.commit();
    }

    clearNotification(docId: string)
    {
        const docRef = this.ngFirestore.collection('user-data').doc(this.user!.id).collection('notifications').doc(docId);
        return docRef.set({ 'read': true }, { merge: true });
    }

    // STRIPE

    addStripeCheckoutSession(checkoutSession: StripeCheckoutSession): Promise<DocumentReference<DocumentData, DocumentData>>
    {
        return addDoc(
            collection(this.firestore, `stripe-customers/${this.user!.id}/checkout_sessions`),
            checkoutSession
        );
    }
}

export interface StripeCheckoutSession
{
    ui_mode: string;
    price: string;
    success_url: string;
    cancel_url: string;
}

export interface ImportSessionRequestData
{
    account: string;
    importType: ImportType.CONTACTS,
    status: ImportSessionStatus;
    isInitialImport: boolean;
    providerType: ProviderType;
}