import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { ProviderType } from "functions/src/providers/auth/models";
import { GoogleRequestParams } from "functions/src/providers/google/auth/models";
import { GoogleCalendarEvent } from "functions/src/providers/google/people-api.models";
import { LinkedInRequestParams, LinkedInTokenResponse } from "functions/src/providers/linkedin/auth/models";
import { Observable, tap } from "rxjs";
import { GoogleTokenResponse } from "src/app.implementations/authentication-external/google/google-auth.models";
import { FirebaseAuthService } from "src/app.implementations/authentication/firebase/security/firebase.auth.service";
import { AppUser } from "../models/app-user.model";

@Injectable({
    providedIn: 'root'
})
export class FunctionsService
{
    private user!: AppUser | null;

    // GOOGLE
    private getGoogleTokenUrl = 'http://localhost:5001/intrepid-honor-376613/europe-west1/getGoogleToken';
    private googleLogoutUrl = 'http://localhost:5001/intrepid-honor-376613/europe-west1/googleLogout';
    private getGoogleEventsUrl = 'http://localhost:5001/intrepid-honor-376613/europe-west1/getGoogleEvents';
    
    // LINKEDIN
    private getLinkedInTokenUrl = 'http://localhost:5001/intrepid-honor-376613/europe-west1/getLinkedInToken';
    private linkedInLogoutUrl = 'http://localhost:5001/intrepid-honor-376613/europe-west1/linkedInLogout';

    constructor(
        private http: HttpClient,
        private auth: Auth,
        private firebaseAuthService: FirebaseAuthService
    )
    {
        this.firebaseAuthService.user$.subscribe(user =>
        {
            this.user = user;
        });
    }

    //Google

    getGoogleToken(data: GoogleRequestParams): Observable<GoogleTokenResponse>
    {
        let options = { headers: { Authorization: `Bearer ${this.user?.token}` } };

        this.auth.currentUser?.getIdToken();

        return this.http.post(this.getGoogleTokenUrl, data, options)
            .pipe(tap({
                next: (response: any) =>
                {
                    console.log('getGoogleToken FUNCTION Response:', response);
                }
            }));
    }

    getGoogleEvents(account: string, timeMin: string, timeMax: string): Observable<GoogleCalendarEvent>
    {
        let options = { headers: { Authorization: `Bearer ${this.user?.token}` } };
        let data = { account: account, timeMin: timeMin, timeMax: timeMax };

        this.auth.currentUser?.getIdToken();

        return this.http.post(this.getGoogleEventsUrl, data, options)
            .pipe(tap({
                next: (response: any) =>
                {
                    //console.log('getGoogleEvents FUNCTION Response:', response);
                }
            }));
    }

    googleLogout(email: string)
    {
        let options = { headers: { Authorization: `Bearer ${this.user?.token}` } };
        let provider = ProviderType.GOOGLE;
        let data = { provider, email };

        this.auth.currentUser?.getIdToken();

        return this.http.post(this.googleLogoutUrl, data, options)
            .pipe(tap({
                next: (response: any) =>
                {
                    //
                },
                error: (error: any) =>
                {
                    //
                }
            }));
    }

    // LinkedIn

    getLinkedInToken(data: LinkedInRequestParams): Observable<LinkedInTokenResponse>
    {
        let options = { headers: { Authorization: `Bearer ${this.user?.token}` } };

        this.auth.currentUser?.getIdToken();

        return this.http.post(this.getLinkedInTokenUrl, data, options)
            .pipe(tap({
                next: (response: any) =>
                {
                    console.log('getGoogleToken FUNCTION Response:', response);
                }
            }));
    }

    linkedInLogout(email: string)
    {
        let options = { headers: { Authorization: `Bearer ${this.user?.token}` } };
        let provider = ProviderType.LINKEDIN;
        let data = { provider, email };

        this.auth.currentUser?.getIdToken();

        return this.http.post(this.linkedInLogoutUrl, data, options)
            .pipe(tap({
                next: (response: any) =>
                {
                    //
                },
                error: (error: any) =>
                {
                    //
                }
            }));
    }

    processImage(imageBase64: string)
    {
        throw new Error('Method not implemented.');
    }
}