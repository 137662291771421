import { Component } from "@angular/core";

@Component({
    selector: 'app-user-icon-svg',
    templateUrl: './user-icon-svg.component.html',
    styleUrls: ['./user-icon-svg.component.scss'],
    standalone: true,
    imports: []
})
export class UserIconSvgComponent
{
}