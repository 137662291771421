import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { Contacts } from '@ionic-native/contacts';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { IonicStorageModule } from '@ionic/storage-angular';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { firstValueFrom } from 'rxjs';
import { firebaseBootstrapProviders } from './app.implementations/authentication/firebase/bootstrap-providers';
import { FirebaseAuthService } from './app.implementations/authentication/firebase/security/firebase.auth.service';
import { stripeBootstrapProviders } from './app.implementations/payment-provider/stripe-firebase/stripe-firebase-providers';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { MergeGroupsStateService } from './app/shared/state/merge-groups-state.service';
import { environment } from './environments/environment';

if (environment.production)
{
	enableProdMode();
}

declare module "@angular/core" {
	interface ModuleWithProviders<T = any>
	{
		ngModule: Type<T>;
		providers?: (Provider | EnvironmentProviders)[] | undefined;
	}
}
export function initializeApp(authService: FirebaseAuthService, mergeGroupsStateService: MergeGroupsStateService)
{
	return () => firstValueFrom(authService.user$).then(user =>
	{
		if (user)
		{
			return mergeGroupsStateService.loadInitialData(user.id);
		}
		return Promise.resolve(); // Return a resolved promise if there's no user
	});
}

bootstrapApplication(AppComponent, {
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [FirebaseAuthService, MergeGroupsStateService],
			multi: true
		},
		provideRouter(routes, withComponentInputBinding()),
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy
		},
		importProvidersFrom(
			HttpClientModule,
			OAuthModule.forRoot(),
			IonicStorageModule.forRoot()
		),
		{
			provide: 'OAuthService',
			useClass: OAuthService,
		},
		Contacts,
		provideIonicAngular(),
		provideAnimations(),
		firebaseBootstrapProviders,
		stripeBootstrapProviders
	],
});