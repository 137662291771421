import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Storage } from '@capacitor/storage';
import { BehaviorSubject, Observable, catchError, lastValueFrom, throwError } from 'rxjs';
import { ToastService } from 'src/app/shared/services/error-handling.service';
import { environment } from 'src/environments/environment';
import { AuthData, FriendsResponseData } from './facebook-auth.models';

/** 
 *  Authentication service that follows OAUTH2 IMPLICIT GRANT FLOW to request authorization for our App,
 *  then gets the access token from the redirect url.
 * 
 *  The access token is used to fetch the users contacts via the Facebook Graph API.
 */

@Injectable({
    providedIn: 'root'
})
export class FacebookAuthService
{
    facebookTokenValid = signal(false);
    readonly state = 'fb12345';

    private readonly token_url = 'https://www.facebook.com/v20.0/dialog/oauth';
    private readonly graph_api_url = 'https://graph.facebook.com/v20.0/me ';
    private readonly storage_key = 'facebook_auth';

    private tokenSubject = new BehaviorSubject<string | null>(null);
    private authStatusSubject = new BehaviorSubject<boolean>(false);

    private clientId = environment.facebook.appId;
    private scopes = environment.facebook.scope;

    constructor(
        private http: HttpClient,
        private toast: ToastService,
        private auth: Auth
    )
    {
        this.checkAndSetAuthStatus();
    }

    public async logout()
    {
        await this.removeStoredToken();
        this.authStatusSubject.next(false);
        this.facebookTokenValid.set(false);
        this.toast.showSuccess('Successfully logged out');
    }

    public async checkAndSetAuthStatus(): Promise<void>
    {
        try
        {
            const authData = await this.getStoredToken();
            if (authData)
            {
                const isValid = await this.validateToken(authData.token);
                this.authStatusSubject.next(isValid);
                this.facebookTokenValid.set(isValid);
                if (isValid)
                {
                    this.tokenSubject.next(authData.token);
                } else
                {
                    await this.removeStoredToken();
                }
            } else
            {
                this.authStatusSubject.next(false);
                this.facebookTokenValid.set(false);
            }
        } catch (error)
        {
            this.toast.showError(error, 'Failed to check authentication status');
            this.authStatusSubject.next(false);
            this.facebookTokenValid.set(false);
        }
    }

    public isLoggedIn(): Observable<boolean>
    {
        return this.authStatusSubject.asObservable();
    }

    public async login(): Promise<void>
    {
        try
        {
            let redirectUri = this.getRedirectUrl();
            const authUrl = this.token_url +
                `?client_id=${this.clientId}` +
                `&response_type=token` +
                `&redirect_uri=${encodeURIComponent(redirectUri)}` +
                `&granted_scopes=${encodeURIComponent(this.scopes)}` +
                `&state=${this.state}`;

            if (Capacitor.isNativePlatform())
            {
                //TODO: testen in echte app
                //await Browser.open({ url: authUrl, presentationStyle: 'popover' } as OpenOptions);

                window.location.href = authUrl;
            }
            else
            {
                window.location.href = authUrl;
            }
        }
        catch (error)
        {
            this.toast.showError(error, 'Failed to initiate login');
        }
    }

    public async handleAppRedirect(event: URLOpenListenerEvent): Promise<void>
    {
        try
        {
            let fragment = event.url.split('#')[1];
            if (fragment)
            {
                this.processRedirectFragment(fragment);
            }
            else
            {
                this.toast.showError(new Error(`No token found in redirectUrl ${event.url}`), 'Failed to complete login');
            }
        }
        catch (error)
        {
            this.toast.showError(error, 'Failed to complete login');
        }
    }

    public async handleBrowserRedirect(fragment: string): Promise<void>
    {
        try
        {
            if (fragment)
            {
                this.processRedirectFragment(fragment);
            }
        }
        catch (error)
        {
            this.toast.showError(error, 'Failed to complete login');
        }
    }

    private getRedirectUrl(): string
    {
        if (Capacitor.getPlatform() === 'web')
        {
            return window.location.origin + window.location.pathname;
        }
        else
        {
            return environment.facebook.redirectUrl;
        }
    }

    private async processRedirectFragment(fragment: string)
    {
        let parsedHash = new URLSearchParams(fragment);
        let token = parsedHash.get("access_token")!;
        let expires_in = parsedHash.get("expires_in")!;
        let expiryDate = Date.now() + Number.parseInt(expires_in) * 1000;
        let user = this.auth.currentUser;

        let authData: AuthData = {
            userId: user!.uid,
            token: token,
            tokenExpirationDate: expiryDate
        };

        this.toast.showSuccess('Successfully logged in');
        await this.setStoredToken(authData);
        this.facebookTokenValid.set(true);
    }

    private async validateToken(token: string): Promise<boolean>
    {
        try
        {
            // await lastValueFrom(this.http.get(this.validate_token_url, {
            //     headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
            // }).pipe(
            //     catchError(this.handleHttpError)
            // ));
            return true;
        } catch
        {
            return false;
        }
    }

    private handleHttpError = (error: HttpErrorResponse) =>
    {
        let errorMessage = 'An error occurred';
        if (error.error instanceof ErrorEvent)
        {
            // Client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else
        {
            // Server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        this.toast.showError(error, errorMessage);
        return throwError(() => new Error(errorMessage));
    }

    private async getStoredToken(): Promise<AuthData | null>
    {
        const { value } = await Storage.get({ key: this.storage_key });

        if (value)
        {
            return JSON.parse(value) as AuthData;
        }

        return null;
    }

    private async setStoredToken(token: AuthData): Promise<void>
    {
        await Storage.set({ key: this.storage_key, value: JSON.stringify(token) });
    }

    private async removeStoredToken(): Promise<void>
    {
        await Storage.remove({ key: this.storage_key });
    }






    public async getContacts(): Promise<FriendsResponseData>
    {
        const token = this.tokenSubject.value;
        if (!token)
        {
            throw new Error('Not authenticated');
        }

        // Unfortunately, the Facebook Graph API does not allow fetching friends anymore. Only friends who also use the app can be fetched.
        return lastValueFrom(this.http.get<FriendsResponseData>(`${this.graph_api_url}/friends?access_token=${token}`)
            .pipe(
                catchError(this.handleHttpError)
            ));
    }
}