import { importProvidersFrom } from '@angular/core';
import { FirebaseAppModule, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';
import { IAuthService } from 'src/app/security/interfaces/auth.service';
import { environment } from 'src/environments/environment';
import { FirebaseAuthService } from './security/firebase.auth.service';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            fullLabel: 'Continue with Google'
        },
        // {
        // 	scopes: [
        // 		'public_profile',
        // 		'email',
        // 		'user_likes',
        // 		'user_friends'
        // 	],
        // 	fullLabel: 'Continue with Facebook',
        // 	customParameters: {
        // 		'auth_type': 'reauthenticate',

        // 	},
        // 	provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // }
    ],
    tosUrl: '/tos',
    privacyPolicyUrl: '/pripol',
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

export const firebaseBootstrapProviders = [
    {
        provide: IAuthService,
        useClass: FirebaseAuthService
    },
    importProvidersFrom(
        FirebaseAppModule,
        AngularFirestoreModule,
        FirebaseUIModule.forRoot(firebaseUiAuthConfig)
    ),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    //provideStorage(() => getStorage()),
    //provideFunctions(() => getFunctions())
]