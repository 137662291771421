export interface Name
{
    metadata: Metadata;
    displayName: string;
    givenName: string;
    displayNameLastFirst: string;
    unstructuredName: string;
}

export interface Metadata
{
    primary: boolean;
    source: Source;
}

export interface Source
{
    type: string;
    id: string;
}

export interface GoogleAuthTokenData
{
    userId?: string;
    token: string;
    tokenExpirationDate: number;
}

export interface GoogleTokenResponse
{
    access_token: string;
    refresh_token: string;
    expires_in: number;
    scope: string;
    token_type: string;
    id_token: string;
}

export const googleScopes = {
    contacts_urlScope: 'https://www.googleapis.com/auth/contacts',
    contactsReadonly_urlScope: 'https://www.googleapis.com/auth/contacts.readonly',
    calendarReadonly_urlScope: 'https://www.googleapis.com/auth/calendar.readonly',
    email_urlScope: 'https://www.googleapis.com/auth/userinfo.email',
    profile_urlScope: 'https://www.googleapis.com/auth/userinfo.profile',
    openid: 'openid',
    profile: 'profile',
    email: 'email'
}