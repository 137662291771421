import { Injectable, inject } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";
import { AppUserInfo } from "../models/app-user.model";

@Injectable({
    providedIn: 'root'
})
export class UserDataService
{
    private ngFirestore = inject(AngularFirestore);

    constructor() { }

    getUserAppInfo$(uid: string): Observable<AppUserInfo>
    {
        return this.ngFirestore.doc(`user-data/${uid}`).valueChanges() as Observable<AppUserInfo>;
    }

    async getUserAppInfo(uid: string): Promise<AppUserInfo>
    {
        let doc = this.ngFirestore.doc(`user-data/${uid}`);        
        let data =  await doc.ref.get();
        return data.data() as AppUserInfo;
    }
}